import React, {FC, memo, useState} from 'react';
import Authentication from '.';
import {UserContext} from '../../context/user-context';

const Context: FC<{children: any}> = ({children}) => {
  const [user, setUser] = useState<any>(null);
  return (
    <UserContext.Provider value={{user, setUser}}>
      <Authentication>{children}</Authentication>
    </UserContext.Provider>
  );
};

export default memo(Context);
