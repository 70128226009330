import { createContext } from "react";

export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  package_data: any;
  picture: any;
  name: string;
  created_at: string;
  request_left: number;
  deleted_at?: string;
  cancel_at?: string;
}

export const UserContext = createContext({
  user: {} as UserType,
  setUser: (user: UserType) => {},
});
