import React, {useContext, useEffect, memo, useCallback, FC} from 'react';
import { navigate } from 'gatsby';
import {UserContext} from '../../context/user-context';
import {getUser} from '../../requests';
import useHasFocus from '../../hooks/useHasFocus';

const Authentication: FC<any> = props => {
  const {setUser} = useContext(UserContext);
  const focus = useHasFocus();
  const getUserInfo = useCallback(async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
    if (userInfo && !userInfo.id && global?.window?.location?.href.includes("my-account")) {
      navigate('/');
    }
  }, [getUser]);

  useEffect(() => {
    getUserInfo();
  }, [focus]);

  return <>{props.children}</>;
};

export default memo(Authentication);
