import axios from 'axios';

// import {getUserToken} from '../helpers';
import {returnAPILink} from '../constants';
import Cookies from 'js-cookie';

axios.interceptors.request.use(function(config) {
  const token = Cookies.get('user-token');
  token && (config.headers.Authorization = token);
  config.baseURL =
    window.location.hostname === 'textreader.ai' ? 'https://api.textreader.ai' : 'https://api.textreader.ai';

  //: 'https://api.searchscraper.io';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export const isValidEmail = (mail: string) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
};

export const isValidDomain = (domain: string) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );

export const request = async (url: string) => {
  try {
    const result = await axios.get(`${returnAPILink()}/${url}`);
    return result;
  } catch (error) {
    return error;
  }
};

export const getUser = async () => {
  try {
    const result = await axios(`/user/get`, {
      method: 'GET',
    });
    return result && result.data && result.data.id ? result.data : {};
  } catch (error) {
    return {error};
  }
};
export const login = async (lang: string) => {
  try {
    const result = await axios(`/user/log-in/${lang}`, {
      method: 'GET',
    });
    return result;
  } catch (error) {
    return error && error.response && error.response.data ? error.response.data.url : '';
  }
};
export const logOut = async () => {
  try {
    const result = await axios(`/user/logout`, {
      method: 'GET',
    });
    return result;
  } catch (error) {
    return {error};
  }
};

export const toolRequest = async (url: string, input?: string, obj?: any) => {
  const result = {
    data: null as any,
    status: 0,
    errorData: null as {statusCode: number; message: string; error: string} | null,
  };

  try {
    const response = await axios.post(`${returnAPILink()}/${url}`, {source: input, ...(obj || {})});
    result.data = response.data;
  } catch (e) {
    //@ts-ignore
    if (e.response) {
      //@ts-ignore
      result.status = e.response.status || 0;
      //@ts-ignore
      result.errorData = e.response.data || '';
    }
  }
  return result;
};

export const toolHistoryRequest = async (toolName: string, page: number) => {
  try {
    const result = await axios.get(`${returnAPILink()}/history`, {
      params: {
        toolName,
        page,
        limit: 10,
      },
    });
    return result.data;
  } catch (e) {
    //@ts-ignore
    return e.response.status;
  }
};
export const toolHistoryRequestById = async (id: string) => {
  try {
    const result = await axios.get(`${returnAPILink()}/history/${id}`);
    return result.data;
  } catch (e) {
    //@ts-ignore
    return e.response.status;
  }
};

export const getVoices = async (lang: string) => {
  try {
    const result = await axios(
      `https://content-texttospeech.googleapis.com/v1/voices?languageCode=${lang}&key=AIzaSyBharYuJlXffjVzuZsPN5zvVvdwq0W0M9M`,
    );
    return result?.data?.voices?.map((item: any) => {
      return {label: item.name, value: item.name, gender: item.ssmlGender};
    });
  } catch (error) {
    return error;
  }
};

export const getRequestsHistory = async (data: any) => {
  try {
    const result = await axios(`/requests/list`, {
      method: 'POST',
      data,
    });
    return result && result.data ? {result: result.data.data, total: result.data.count} : null;
  } catch (error) {
    return {error};
  }
};

export const getPackages = async () => {
  try {
    const result = await axios(`/packages/list`, {
      method: 'GET',
    });
    return result && result.data ? result.data : [];
  } catch (error) {
    return {error};
  }
};

export const createCard = async (token: any, index: any, paymentType: any) => {
  try {
    const result = await axios(`/payment/create`, {
      method: 'POST',
      data: {
        source: token,
        package: index,
        payment_type: paymentType,
        return_url: paymentType === 'paypal' ? 'https://textreader.ai' : '',
      },
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const deleteAccount = async () => {
  try {
    const result = await axios(`/user/delete`, {
      method: 'GET',
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const cancelDeleteAccount = async () => {
  try {
    const result = await axios(`/user/delete/cancel`, {
      method: 'GET',
    });
    return result && result.data ? result.data : false;
  } catch (error) {
    return {error: error.response};
  }
};

export const paymentCancelResume = async (type: any, cancelAt: any) => {
  try {
    const result = await axios(`/payment/cancel`, {
      method: 'POST',
      data: {
        payment_type: type,
        cancel_at_period_end: cancelAt,
      },
    });
    return;
  } catch (error) {
    return {error};
  }
};
